<template>
  <div class="container with-price-box">
    <div>
      <h1>Red Missile Chili 100g</h1>
      <p>
        Ein Beutel mit 100g Red Missile Chili, die hier auf dem Bauernhof angebaut wurden.
      </p>

      <picture>
        <a href="/img/shop/RedMissile-Bag100-1080.jpg"><img src="@/assets/images/RedMissile-Bag100-250.jpg" /></a>
      </picture>
      <p>
        Im Bild sieht man einen Teil unseres zukünftigen Waldgartens. Wir haben dort als Experiment Red Missile Chili
        angepflanzt, um lebendige Wurzeln im Boden zu haben. Entsprechend unseres Konzeptes bieten wir den von der Natur
        bereitgestellten Überfluß hier im Hofladen an.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-1-1080.jpg"><img src="@/assets/images/RedMissile-B02-1-250.jpg" /></a>
      </picture>
      <p>
        Die Chilibohnen werden von Hand geerntet und in einem Eimer zum Sortieren gebracht.
      </p>

      <picture>
        <a href="/img/shop/RedMissile-B02-2-1080.jpg"><img src="@/assets/images/RedMissile-B02-2-250.jpg" /></a>
      </picture>
      <p>
        Das Sortieren ist dann ein eher langwieriger Vorgang, weil jede Chilibohne einzeln vom Zweig und Blättern
        getrennt werden muß. Es sind immer wieder ein paar dabei, die nicht reif geworden sind und diese müssen
        aussortiert werden.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-3-1080.jpg"><img src="@/assets/images/RedMissile-B02-3-250.jpg" /></a>
      </picture>
      <p>
        Am Ende werden die Chilibohnen dann in Vakuumbeutel verpackt.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-4-1080.jpg"><img src="@/assets/images/RedMissile-B02-4-250.jpg" /></a>
      </picture>

      <p>
      </p>
    </div>
    <PriceBox product="fdb968df-abc9-4d14-99e5-5bcc6006a119" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'

export default {
  name: "RedMissile100DE",
  components: {
    PriceBox,
  },
}

</script>
